import './styles/main.scss'
import './styles/flex.scss'

import HomeOne from './HomeOne'
import React from 'react'
import useAckee from 'use-ackee'

function App() {
  useAckee('/', {
    server: 'https://stats.pranjaljain.me',
    domainId: '6df9d6d5-df96-4601-b807-53ca90fb244c'
  }, {
    detailed: true,
    ignoreLocalhost: true,
  })

  return (
    <HomeOne />
  )
}

export default App
