import "fullpage.js/vendors/scrolloverflow";
import './styles/main.scss'
import './styles/flex.scss'

import React, { useState } from 'react'

import Aryan from './assets/New/aaryan.png'
import Hetvi from './assets/New/hetvi.png'
import { ReactComponent as InstagramIcon } from './assets/New/Instagram.svg'
import { ReactComponent as LinkedInIcon } from './assets/New/LinkedIN.svg'
import { ReactComponent as LogoBlack } from './assets/New/Yellow Mushrooms Logo.svg'
import { ReactComponent as LogoWhite } from './assets/New/Yellow Mushrooms Logo.svg'
import { ReactComponent as Mushroom } from './assets/mushroom.svg'
import { ReactComponent as Namaste } from './assets/New/Namaste.svg'
import Vipul from './assets/New/vipul.png'
import loadable from '@loadable/component'
import { useMediaQuery } from 'react-responsive'

const ReactFullpage = loadable(() => import('@fullpage/react-fullpage'))
const PageNumber = loadable(() => import('./components/PageNumber'))

function HomeOne() {
    const isMobileDevice = useMediaQuery({
        query: '(max-width: 600px)'
    })
    const [activeSlide, setActiveSlide] = useState(0)
    const [startAnimation, setStartAnimation] = useState(false)
    const [logoAnimation, setLogoAnimation] = useState(0)

    let logo = [
        <LogoBlack className="logo" id={startAnimation ? "logoin" : "logoout"} />,
        <LogoBlack className="logo" id={startAnimation ? "logoin" : "logoout"} />,
        <LogoWhite className="logo" id={startAnimation ? "logoin" : "logoout"} />,
        <LogoWhite className="logo" id={startAnimation ? "logoin" : "logoout"} />,
        <LogoBlack className="logo" id={startAnimation ? "logoin" : "logoout"} />,
        isMobileDevice ? <LogoBlack className="logo" id={startAnimation ? "logoin" : "logoout"} /> : <LogoBlack className="logo hide" id={startAnimation ? "logoin" : "logoout"} />,
        <LogoBlack className="logo" id={startAnimation ? "logoin" : "logoout"} />,
        <LogoBlack className="logo hide" id={startAnimation ? "logoin" : "logoout"} />,]

    let number_class = ["title white", "title white", "title white", "title white", "title white", "title white", "title white", "title white", "title"];
    let title = ['VISION', 'SOUL', 'Services', 'TEAM', 'Connect']
    let title_responsive = ['VISION', 'SOUL', 'Services', 'TEAM', 'TEAM', 'TEAM', 'Connect']

    return (
        <>
            <div className="logo_container">
                <div className="logo_main">
                    {
                        logo[logoAnimation]
                    }
                </div>
                <div className="home_border">
                    <div className="left"></div>
                    <div className="right"></div>
                    <div className="top"></div>
                    <div className="bottom"></div>
                </div>

            </div>
            <PageNumber activeSlide={activeSlide} startAnimation={startAnimation} />
            <div id="slides">
                <ReactFullpage
                    scrollingSpeed={1200}
                    afterLoad={(origin, destination, direction) => {
                        setActiveSlide(destination.index)
                        setStartAnimation(true)
                    }}
                    onLeave={(origin, destination, direction) => {
                        setLogoAnimation(destination.index)
                        setStartAnimation(false)
                    }}
                    navigation
                    scrollOverflow={true}
                    normalScrolling="#intro"
                    render={({ state, fullpageApi }) => {

                        return (
                            <div>
                                <div className="section full white flex">
                                    <div className="content_holder flex flex-center offwhite" id="intro">
                                        <div className="container">
                                            <div className="container--inner">
                                                <Namaste className="show" />
                                            </div>
                                            <a href="mailto:namaste@theyellowmushrooms.com" className="maillink">namaste@theyellowmushrooms.com</a>
                                            <div className="scroll_indicator">
                                                <div className="scroll_indicator_bar"></div>
                                                <Mushroom className="scroll_mushroom" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="section flex">
                                    <div className="content_holder flex flex-center offwhite flex-row">
                                        <div className="flex-1 flex flex-col">
                                            <div className="section_text" id={startAnimation ? "textin" : "textout"}>
                                                <h2>WE ARE THE <br />FUN N’ GUS CREATIVES.</h2>
                                                <p>Yellow Mushrooms is a design studio founded by a pack of design practitioners hailing from diverse backgrounds. We are visionaries, young blood and intellectual creatives, wanting to offer a fresh perspective and meaning to the already existing monotonous design approaches followed by some of the design studios in India.  </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="section black flex">
                                    <div className="content_holder flex flex-center flex-row">
                                        <div className="flex-1 flex flex-col">
                                            <div className="section_text" id={startAnimation ? "textin" : "textout"}>
                                                <h2>We pride ourselves in being connected to our Desi roots.</h2>
                                                <p>What happens when a Communication, an Industrial and a UI / UX Designer come  together? They brawl! Just Kidding, they bring in the expertise of their respective fields and they create tangible, digital and empowering products. </p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="section black flex">
                                    <div className="content_holder flex flex-center flex-row">
                                        <div className="flex-1 flex flex-center flex-col">
                                            <div className="section_text" id={startAnimation ? "textin" : "textout"}>
                                                <h2>IT IS MERAKI FOR US!</h2>
                                                <p>The services we offer are based on thorough research and the design methodologies used are not skin deep.</p>
                                                <ul>
                                                    <li>Graphic Design | Brand Identity Design</li>
                                                    <li>User Experience Design | User Interface Design</li>
                                                    <li>Product Design | Social Impact DESIGN</li>
                                                    <li>DIGITAL MARKETING & SOCIAL MEDIA</li>
                                                    <li>CURATION & COLLABORATION</li>
                                                </ul>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                {!isMobileDevice ? <div className="section flex">
                                    <div className="content_holder flex flex-center offwhite flex-row">
                                        <div className="flex flex-row full-w flex-space-between" id="team">
                                            <div className="flex flex-1 flex-j-center m-top-100">
                                                <div className="team-member" id={startAnimation ? "textin" : "textout"}>
                                                    <img loading="lazy" src={Aryan} alt="Aaryan" className="team-member-image " />
                                                    <h2>Aaryan
                                                        <a href="https://www.linkedin.com/in/aaryanmerani/">
                                                            <LinkedInIcon fill="#fff" className="linkedInIcon" />
                                                        </a></h2>
                                                    <p>A Visual Communication design practitioner & an artist. He can never stop thinking about eating or cooking. Aaryan has successfully worked on various branding and other challenging projects.</p>
                                                </div>
                                            </div>
                                            <div className="flex flex-1 flex-j-center m-top-100" >
                                                <div className="team-member" id={startAnimation ? "textin" : "textout"}>
                                                    <img loading="lazy" src={Hetvi} alt="Hetvi" className="team-member-image" />
                                                    <h2>Hetvi <a href="https://www.linkedin.com/in/hetvimaheshshah/">
                                                        <LinkedInIcon fill="#fff" className="linkedInIcon" />
                                                    </a></h2>
                                                    <p>An avid trekker, a UX designer, a social design advocate, and a fierce practitioner of design research and methodologies. Hetvi has notable experience in working with corporates and NGOs.</p>
                                                </div>
                                            </div>
                                            <div className="flex flex-1 flex-j-center m-top-100" >
                                                <div className="team-member" id={startAnimation ? "textin" : "textout"}>
                                                    <img loading="lazy" src={Vipul} alt="Vipul" className="team-member-image" />
                                                    <h2>Vipul <a href="https://www.linkedin.com/in/vipul92jadhav/">
                                                        <LinkedInIcon fill="#fff" className="linkedInIcon" />
                                                    </a></h2>
                                                    <p>An Industrial Design practitioner, empath, and a motorcyclist who adores touring. He has proven experience in working with startups on social and automotive projects.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> : <>
                                    <div className="section flex">
                                        <div className="content_holder flex flex-center offwhite flex-row">
                                            <div className="flex flex-row full-w flex-space-between" id="team">
                                                <div className="flex flex-1 flex-j-center m-top-100" >
                                                    <div className="team-member" id={startAnimation ? "textin" : "textout"}>
                                                        <img loading="lazy" src={Aryan} alt="Aaryan" className="team-member-image " />
                                                        <h2>Aaryan <a href="https://www.linkedin.com/in/aaryanmerani/">
                                                            <LinkedInIcon fill="#fff" className="linkedInIcon" />
                                                        </a></h2>
                                                        <p>A Visual Communication design practitioner & an artist. He can never stop thinking about eating or cooking. Aaryan has successfully worked on various branding and other challenging projects.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="section flex">
                                        <div className="content_holder flex flex-center offwhite flex-row">
                                            <div className="flex flex-row full-w flex-space-between" id="team">
                                                <div className="flex flex-1 flex-j-center m-top-100" >
                                                    <div className="team-member" id={startAnimation ? "textin" : "textout"}>
                                                        <img loading="lazy" src={Hetvi} alt="Hetvi" className="team-member-image" />
                                                        <h2>Hetvi <a href="https://www.linkedin.com/in/hetvimaheshshah/">
                                                            <LinkedInIcon fill="#fff" className="linkedInIcon" />
                                                        </a></h2>
                                                        <p>An avid trekker, a UX designer, a social design advocate, and a fierce practitioner of design research and methodologies. Hetvi has notable experience in working with corporates and NGOs.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="section flex">
                                        <div className="content_holder flex flex-center offwhite flex-row">
                                            <div className="flex flex-row full-w flex-space-between" id="team">
                                                <div className="flex flex-1 flex-j-center m-top-100" >
                                                    <div className="team-member" id={startAnimation ? "textin" : "textout"}>
                                                        <img loading="lazy" src={Vipul} alt="Vipul" className="team-member-image" />
                                                        <h2>Vipul <a href="https://www.linkedin.com/in/vipul92jadhav/">
                                                            <LinkedInIcon fill="#fff" className="linkedInIcon" />
                                                        </a></h2>
                                                        <p>An Industrial Design practitioner, empath, and a motorcyclist who adores touring. He has proven experience in working with startups on social and automotive projects.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div></>}
                                <div className="section">
                                    <div className="slide" style={{
                                        overflow: "scroll",
                                    }}>
                                        <div className="content_holder flex flex-end offwhite flex-row">
                                            <LogoBlack className="logo_last" id={startAnimation ? "logoin" : "logoout"} />
                                            <div className="flex-1 flex flex-col">
                                                <div id={startAnimation ? "in" : "out"} className="page-number-last">
                                                    <h1> ०५ </h1>
                                                    <hr />
                                                    {/* <h1 class="title">Connect</h1> */}
                                                    {!isMobileDevice ? <h1 className={number_class[activeSlide - 1]}>{title[activeSlide - 1]}
                                                        {
                                                            activeSlide !== 0 ? <Mushroom width="12px" className="icon" /> : <></>
                                                        }
                                                    </h1> : <h1 className={number_class[activeSlide - 1]}>{title_responsive[activeSlide - 1]}
                                                        {
                                                            activeSlide !== 0 ? <Mushroom width="12px" className="icon" /> : <></>
                                                        }
                                                    </h1>}
                                                </div>
                                                <div className="section_text" id={startAnimation ? "textin" : "textout"}>
                                                    <h2>Still Cautious? Hmmm… Let us talk you through this and change your mind!</h2>
                                                    <p>We are the most approachable lads in the design industry. Also, probably the most honest chaps too. Have a project in the back of your head awaiting to prototype, we will do it for you.  Not for free of course, we have our stomachs to fill and a studio to run. We are project scavengers but also holy men who can hear your design worries and sort it over a pint of beer, cup of chai, coffee on a tapri or online.  </p>
                                                    <div className="social_icons">
                                                        <a href="https://www.linkedin.com/company/namasteyellowmushrooms/"><LinkedInIcon className="Icon" /></a>
                                                        <a href="https://www.instagram.com/theyellowmushrooms/"><InstagramIcon className="Icon" /></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="footer flex flex-row flex-col-rev">
                                            <div className="flex-1 flex flex-center">
                                                <LogoBlack className="fav_footer" />
                                            </div>
                                            <div className="flex-1 flex flex-j-center flex-col-nr footer_text">

                                                <p>“Hey you just met us and this is crazy.<br /> But here’s our number, so call us maybe!”</p>
                                                <p>+ 91 88797<span style={{ color: "#FFCE00" }}>449</span>97
                                                    <br />+ 91 998<span style={{ color: "#FFCE00" }}>555</span>0998</p>
                                                <a href="mailto: namaste@theyellowmushrooms.com">namaste@the<span style={{ color: "#FFCE00" }}>yellow</span>mushrooms.com</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        );
                    }}
                />
            </div>

        </>
    )
}

export default HomeOne
